<template>
  <div class="login container h-100 v-100">
    <div class="d-flex justify-content-center h-100">
      <div class="user_card">
        <div class="d-flex justify-content-center">
          <div class="brand_logo_container">
            <img
              src="@/assets/logo.png"
              class="brand_logo"
              alt="Logo"
            />
          </div>
        </div>
        <div v-if="loggingIn" class="container-loading">
          <i class="fas fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div v-if="loginError" class="container-loading">
          <i class="fas fa-spinner fa-pulse fa-5x"></i>
        </div>
        <p v-if="loginError">{{ loginError }}</p>
        <div class="d-flex justify-content-center form_container">
          <form @submit.prevent="loginSubmit">
            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text"
                  ><i class="fas fa-user"></i
                ></span>
              </div>
              <input
                type="email"
                name=""
                class="form-control input_user"
                value=""
                :placeholder="$t('Copla.Common.Form.Email')"
                v-model="email"
                required="required"
              />
            </div>
            <div class="input-group mb-2">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-key"></i></span>
              </div>
              <input
                type="password"
                name=""
                class="form-control input_pass"
                value=""
                :placeholder="$t('Copla.Common.Form.Password')"
                v-model="password"
                required="required"
              />
            </div>
            <div class="d-flex justify-content-center mt-3 login_container">
              <button type="submit" name="button" class="btn login_btn">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapState(["loggingIn", "loginError"])
  },
  methods: {
    ...mapActions(["doLogin"]),
    loginSubmit() {
      const params = new URLSearchParams();
      params.append("_login", this.email);
      params.append("_password", this.password);
      this.doLogin(params);
    }
  }
};
</script>

<style scoped>
.user_card {
  height: 400px;
  width: 350px;
  margin-top: 25vh;
  margin-bottom: auto;
  background: #7eb928;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background: white;
  padding: 10px;
  text-align: center;
}
.brand_logo {
  width: 150px;
  margin-top: 50px;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #505050 !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #505050 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #505050 !important;
}
</style>
