<template>
  <div class="body">
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  name: "Home",
  components: {
    LoginForm
  }
};
</script>

<style scoped>
.body {
  background-color: #505050;
  height: 100vh;
}
</style>
